export const providers = [
  {
    slug: 'netflix',
    name: 'Netflix',
    logo: '/assets/logos/providers/netflix-logo.svg',
    id: '976f73f7-6edb-445a-bcdc-693ccd635c65',
  },
  {
    name: 'Amazon Prime Video',
    slug: 'amazon-prime-video',
    logo: '/assets/logos/providers/amazon-prime-video-logo.svg',
    id: '976f73f7-706e-4871-9dde-b736d0e08c5e',
  },
  {
    name: 'Disney Plus',
    slug: 'disney-plus',
    logo: '/assets/logos/providers/disney-plus-logo.svg',
    id: '976f73f7-7073-40f6-9889-4ddcdfa52ecf',
  },
  {
    name: 'Amazon Video',
    slug: 'amazon-video',
    logo: '/assets/logos/providers/amazon-prime-logo.webp',
    id: '976f73f7-7076-4099-acc1-05869c066a61',
  },
  {
    name: 'Apple TV Plus',
    slug: 'apple-tv-plus',
    logo: '/assets/logos/providers/appletv-plus-Logo.svg',
    id: '976f73f7-7079-4942-89b8-2aaa5d7a70a0',
  },
  {
    name: 'Apple iTunes',
    slug: 'apple-itunes',
    logo: '/assets/logos/providers/apple_itunes_logo.svg',
    id: '976f73f7-707c-4ee4-bbf9-2049d33baa30',
  },
  {
    name: 'Google Play Movies',
    slug: 'google-play-movies',
    logo: '/assets/logos/providers/google-play-movies-tv.svg',
    id: '976f73f7-707e-4381-9a52-6e05d52cd519',
  },
  {
    name: 'DAZN',
    slug: 'dazn',
    logo: '/assets/logos/providers/dazn_logo.svg',
    id: '976f73f7-7080-460e-9307-7757620337fb',
  },
  {
    name: 'WOW',
    slug: 'wow',
    logo: '/assets/logos/providers/wow_logo.svg',
    id: '976f73f7-7083-42be-9f9c-b416ec4e2518',
  },
  {
    name: 'Sky Go',
    slug: 'sky-go',
    logo: '/assets/logos/providers/sky-go-logo.svg',
    id: '976f73f7-7085-4c77-852c-2cdbd47b4764',
  },
  {
    name: 'YouTube',
    slug: 'youtube',
    logo: '/assets/logos/providers/youtube-logo.svg',
    id: '976f73f7-7087-4792-8998-6f60df1487af',
  },
  {
    name: 'Sky Store',
    slug: 'sky-store',
    logo: '/assets/logos/providers/sky-store-logo.svg',
    id: '976f73f7-7089-4253-b54d-c5582bcabd2b',
  },
  {
    name: 'Sky Sport',
    slug: 'sky-sport',
    logo: '/assets/logos/providers/sky-sport-logo.svg',
    id: '976f73f7-708c-4459-98e1-d5d231678b72',
  },
  {
    name: 'Joyn',
    slug: 'joyn',
    logo: '/assets/logos/providers/joyn-logo.svg',
    id: '976f73f7-708f-4244-a214-515e0f2a8639',
  },
  {
    name: 'Rakuten TV',
    slug: 'rakuten-tv',
    logo: '/assets/logos/providers/rakuten_tv_logo.svg',
    id: '976f73f7-7091-46d1-b3a2-be75ea9fc03f',
  },
  {
    name: 'RTL+',
    slug: 'rtl',
    logo: '/assets/logos/providers/RTL+_logo.svg',
    id: '976f73f7-7093-49ac-987f-b704068262e5',
  },
  {
    name: 'Chili',
    slug: 'chili',
    logo: '/assets/logos/providers/chili-logo.svg',
    id: '976f73f7-7096-437e-bbbd-0b73bb3877d4',
  },
  {
    name: 'maxdome Store',
    slug: 'maxdome-store',
    logo: '/assets/logos/providers/maxdome_logo.svg',
    id: '976f73f7-7098-49da-9557-2afe3dc8e75a',
  },
  {
    name: 'Videobuster',
    slug: 'videobuster',
    logo: '/assets/logos/providers/videobuster-logo.svg',
    id: '976f73f7-709a-4af6-bc89-4d45c2318b92',
  },
  {
    name: 'YouTube Premium',
    slug: 'youtube-premium',
    logo: '/assets/logos/providers/youztube-premium-logo.svg',
    id: '976f73f7-709e-4f60-9a3e-4aa81a3fe6c7',
  },
  {
    name: 'hayu',
    slug: 'hayu',
    logo: '/assets/logos/providers/hayu_logo.svg',
    id: '976f73f7-70a0-4da2-9d06-3ea1a3a215e9',
  },
  {
    name: 'Alleskino',
    slug: 'alleskino',
    logo: '/assets/logos/providers/alles-kino-logo.svg',
    id: '976f73f7-70a2-44f3-a9ed-aba8781ec464',
  },
  {
    name: 'Arte',
    slug: 'arte',
    logo: '/assets/logos/providers/arte-logo.svg',
    id: '976f73f7-70a5-4a3f-8cab-f9bf46529b64',
  },
  {
    name: 'MagentaTV',
    slug: 'magentatv',
    logo: '/assets/logos/providers/magenta-tv-Logo.svg',
    id: '976f73f7-70a7-40e8-95ce-9a382b9b0ff4',
  },
  {
    name: 'Das Erste Mediathek',
    slug: 'das-erste-mediathek',
    logo: '/assets/logos/providers/das-erste-mediathek-logo.svg',
    id: '976f73f7-70a9-43e3-95ee-56137acbc035',
  },
  {
    name: 'Sooner',
    slug: 'sooner',
    logo: '/assets/logos/providers/sooner_logo.svg',
    id: '976f73f7-70ac-496b-a208-aa8bc969400f',
  },
  {
    name: 'Mubi',
    slug: 'mubi',
    logo: '/assets/logos/providers/mubi-logo.svg',
    id: '976f73f7-70ae-45eb-82cf-fee6a96258f2',
  },
  {
    name: 'Flimmit',
    slug: 'flimmit',
    logo: '/assets/logos/providers/flimmit-logo.svg',
    id: '976f73f7-70b0-4d48-a318-58ddb6e1f485',
  },
  {
    name: 'Netzkino',
    slug: 'netzkino',
    logo: '/assets/logos/providers/netzkino-logo.svg',
    id: '976f73f7-70b3-4cc5-8148-4505eab6bab9',
  },
  {
    name: 'Kividoo',
    slug: 'kividoo',
    logo: null,
    id: '976f73f7-70b5-4bed-8f4e-5fc55eb399d1',
  },
  {
    name: 'Netflix Kids',
    slug: 'netflix-kids',
    logo: null,
    id: '976f73f7-70b7-4a93-b495-a7a864d41a98',
  },
  {
    name: 'GuideDoc',
    slug: 'guidedoc',
    logo: '/assets/logos/providers/guidedoc-logo.webp',
    id: '976f73f7-70ba-4944-9f3d-815b3028b82d',
  },
  {
    name: 'Microsoft Store',
    slug: 'microsoft-store',
    logo: '/assets/logos/providers/microsoft-store-logo.svg',
    id: '976f73f7-70bd-4e1f-ac8b-8d77c4624f08',
  },
  {
    name: 'Shudder',
    slug: 'shudder',
    logo: '/assets/logos/providers/Shudder-logo.png',
    id: '976f73f7-70bf-4568-88fe-c560099cc148',
  },
  {
    name: 'Pantaflix',
    slug: 'pantaflix',
    logo: '/assets/logos/providers/pantaflix-logo.svg',
    id: '976f73f7-70c2-4d75-8465-b49ebe5610d6',
  },
  {
    name: 'Starz Play Amazon Channel',
    slug: 'starz-play-amazon-channel',
    logo: '/assets/logos/providers/starzplay-logo.svg',
    id: '976f73f7-70c4-48cb-a199-1ee9eee1cbf5',
  },
  {
    name: 'Starz',
    slug: 'starz',
    logo: '/assets/logos/providers/starz-logo.svg',
    id: '976f73f7-70c6-42a9-befc-1e1bafe8524e',
  },
  {
    name: 'ZDF Herzkino Amazon Channel',
    slug: 'zdf-herzkino-amazon-channel',
    logo: '/assets/logos/providers/zdf-herzkino-logo.svg',
    id: '976f73f7-70c9-4b87-afc6-a31b4fd105c1',
  },
  {
    name: 'BBC Player Amazon Channel',
    slug: 'bbc-player-amazon-channel',
    logo: '/assets/logos/providers/BBC-player-logo.svg',
    id: '976f73f7-70cb-49ff-b504-b5b38c243b6c',
  },
  {
    name: 'Mubi Amazon Channel',
    slug: 'mubi-amazon-channel',
    logo: '/assets/logos/providers/mubi-logo.svg',
    id: '976f73f7-70cd-4b51-998b-b2bcdc3b09bc',
  },
  {
    name: 'Shudder Amazon Channel',
    slug: 'shudder-amazon-channel',
    logo: '/assets/logos/providers/Shudder-logo.png',
    id: '976f73f7-70d0-4b46-98e8-56bc68bd3fc3',
  },
  {
    name: 'Amazon DVD / Blu-ray',
    slug: 'amazon-dvd-blu-ray',
    logo: null,
    id: '976f73f7-70d2-4758-8fe6-f25fccb8438e',
  },
  {
    name: 'Animax Plus Amazon Channel',
    slug: 'animax-plus-amazon-channel',
    logo: '/assets/logos/providers/animax_logo.svg',
    id: '976f73f7-70d4-4eff-8670-95db0d646db6',
  },
  {
    name: 'Filmtastic Amazon Channel',
    slug: 'filmtastic-amazon-channel',
    logo: null,
    id: '976f73f7-70d6-410d-959d-483d13078fe4',
  },
  {
    name: 'Crunchyroll',
    slug: 'crunchyroll',
    logo: '/assets/logos/providers/crunchyroll-logo.svg',
    id: '976f73f7-70d9-4e09-96d2-54971536c2ad',
  },
  {
    name: 'WAKANIM',
    slug: 'wakanim',
    logo: '/assets/logos/providers/wakanim-logo.png',
    id: '976f73f7-70db-43ea-9940-6deb0a8f044c',
  },
  {
    name: 'wedotv',
    slug: 'wedotv',
    logo: '/assets/logos/providers/wedotv-logo.png',
    id: '976f73f7-70dd-42c2-b77d-c92286ab76f3',
  },
  {
    name: 'blutv',
    slug: 'blutv',
    logo: '/assets/logos/providers/BluTV-logo.svg',
    id: '976f73f7-70e0-4cf5-bbe0-a6755b604723',
  },
  {
    name: 'Kino on Demand',
    slug: 'kino-on-demand',
    logo: '/assets/logos/providers/kinoondemand-logo.png',
    id: '976f73f7-70e2-4e33-a72b-72e5269a1cad',
  },
  {
    name: 'Rakuten Viki',
    slug: 'rakuten-viki',
    logo: '/assets/logos/providers/rakuten-viki-logo.svg',
    id: '976f73f7-70e4-4bcc-b32e-caf12e76d00e',
  },
  {
    name: 'Joyn Plus',
    slug: 'joyn-plus',
    logo: '/assets/logos/providers/joyn-plus-logo.svg',
    id: '976f73f7-70e7-4811-bd35-4d66a1aa54dd',
  },
  {
    name: 'CONtv',
    slug: 'contv',
    logo: '/assets/logos/providers/contv-logo.png',
    id: '976f73f7-70e9-4c93-ac10-7a800fc5e46e',
  },
  {
    name: 'Curiosity Stream',
    slug: 'curiosity-stream',
    logo: '/assets/logos/providers/curiosityStream-logo.svg',
    id: '976f73f7-70ec-4534-87ea-f603de6d0d73',
  },
  {
    name: 'DOCSVILLE',
    slug: 'docsville',
    logo: null,
    id: '976f73f7-70ee-4675-96ac-0e02e4876174',
  },
  {
    name: 'Home of Horror',
    slug: 'home-of-horror',
    logo: '/assets/logos/providers/home-of-horror-logo.svg',
    id: '976f73f7-70f0-46ba-ade8-90948b5f1a7d',
  },
  {
    name: 'Filmtastic',
    slug: 'filmtastic',
    logo: null,
    id: '976f73f7-70f2-4264-92bf-1907eb8263d1',
  },
  {
    name: 'ArthouseCNMA',
    slug: 'arthousecnma',
    logo: '/assets/logos/providers/arthouse-logo.svg',
    id: '976f73f7-70f5-495e-a43d-904587eb2b11',
  },
  {
    name: 'Popcorntimes',
    slug: 'popcorntimes',
    logo: '/assets/logos/providers/popcorntime-Logo.png',
    id: '976f73f7-70f8-450c-b64b-20d7cdd2ce1a',
  },
  {
    name: 'South Park',
    slug: 'south-park',
    logo: null,
    id: '976f73f7-70fa-4356-a86a-7fa8dd86d27a',
  },
  {
    name: 'Spamflix',
    slug: 'spamflix',
    logo: '/assets/logos/providers/spamflix-logo.svg',
    id: '976f73f7-70fc-42f3-99ac-a5d9e674ae28',
  },
  {
    name: 'LaCinetek',
    slug: 'lacinetek',
    logo: '/assets/logos/providers/lacinetek-logo.png',
    id: '976f73f7-70ff-4cbf-aae2-6fa2c61a100e',
  },
  {
    name: 'Amazon Arthaus Channel',
    slug: 'amazon-arthaus-channel',
    logo: '/assets/logos/providers/arthaus-logo.svg',
    id: '976f73f7-7102-4d90-a8ea-20deaf084c94',
  },
  {
    name: 'Arthouse CNMA Amazon Channel',
    slug: 'arthouse-cnma-amazon-channel',
    logo: '/assets/logos/providers/arthouse-logo.svg',
    id: '976f73f7-7104-4a98-830a-86a172b10ca6',
  },
  {
    name: 'ZDF',
    slug: 'zdf',
    logo: '/assets/logos/providers/zdf-logo.svg',
    id: '976f73f7-7107-4209-8ce4-37fd1fd0d8c8',
  },
  {
    name: 'Plex',
    slug: 'plex',
    logo: '/assets/logos/providers/plexlogo.svg',
    id: '976f73f7-7109-4884-8fc7-28d566cddf6a',
  },
  {
    name: 'WOW Presents Plus',
    slug: 'wow-presents-plus',
    logo: '/assets/logos/providers/wow_logo.svg',
    id: '976f73f7-710b-4283-b9a0-464520f4fcd5',
  },
  {
    name: 'filmfriend',
    slug: 'filmfriend',
    logo: '/assets/logos/providers/filmfriend-logo.svg',
    id: '976f73f7-710e-4be4-90c3-e959ca931d1f',
  },
  {
    name: 'Magellan TV',
    slug: 'magellan-tv',
    logo: '/assets/logos/providers/magellan-logo.svg',
    id: '976f73f7-7110-4f73-b32e-ff7cd5883786',
  },
  {
    name: 'BroadwayHD',
    slug: 'broadwayhd',
    logo: '/assets/logos/providers/broadwayhd-logo.png',
    id: '976f73f7-7113-423b-a089-b66589c6b694',
  },
  {
    name: 'Filmzie',
    slug: 'filmzie',
    logo: null,
    id: '976f73f7-7115-424c-a590-7c39c6db4b3a',
  },
  {
    name: 'MovieSaints',
    slug: 'moviesaints',
    logo: null,
    id: '976f73f7-7118-4c28-9d66-5686cd75bbff',
  },
  {
    name: 'Dekkoo',
    slug: 'dekkoo',
    logo: '/assets/logos/providers/dekkoo-logo.png',
    id: '976f73f7-711a-4829-867a-cba5b7284ac1',
  },
  {
    name: 'Dogwoof On Demand',
    slug: 'dogwoof-on-demand',
    logo: '/assets/logos/providers/dogwoof-logo.png',
    id: '976f73f7-711c-47dd-8021-2743dbbc342e',
  },
  {
    name: 'True Story',
    slug: 'true-story',
    logo: '/assets/logos/providers/true-stories-logo.png',
    id: '976f73f7-711f-44b4-b1f5-dd9f1cd5bd47',
  },
  {
    name: 'DocAlliance Films',
    slug: 'docalliance-films',
    logo: '/assets/logos/providers/doc-alliance-logo.svg',
    id: '976f73f7-7121-4dc7-9479-ef12d7f33f3a',
  },
  {
    name: 'Hoichoi',
    slug: 'hoichoi',
    logo: '/assets/logos/providers/hoichoi-logo.png',
    id: '976f73f7-7123-423e-91ca-17039e22ca75',
  },
  {
    name: 'Pluto TV',
    slug: 'pluto-tv',
    logo: '/assets/logos/providers/plutotv-logo.svg',
    id: '976f73f7-7125-4ccb-a14b-cb21fd01d46d',
  },
  {
    name: 'Eventive',
    slug: 'eventive',
    logo: '/assets/logos/providers/eventive-logo.png',
    id: '976f73f7-7128-4d09-a386-1f9d39e173fe',
  },
  {
    name: 'Filmlegenden Amazon Channel',
    slug: 'filmlegenden-amazon-channel',
    logo: null,
    id: '976f73f7-712a-4777-a937-07742bbee693',
  },
  {
    name: 'Cinema of Hearts Amazon Channel',
    slug: 'cinema-of-hearts-amazon-channel',
    logo: '/assets/logos/providers/cinema-hearts.png',
    id: '976f73f7-712c-4e6a-993a-b4cfd4ba26b4',
  },
  {
    name: 'Home of Horror Amazon Channel',
    slug: 'home-of-horror-amazon-channel',
    logo: '/assets/logos/providers/home-of-horror-logo.svg',
    id: '976f73f7-712f-4053-9548-7f9b1ceff5b8',
  },
  {
    name: 'Bloody Movies Amazon Channel',
    slug: 'bloody-movies-amazon-channel',
    logo: '/assets/logos/providers/bloody-movies-logo.png',
    id: '976f73f7-7131-47ec-8be9-b39a310aaa88',
  },
  {
    name: 'Film Total Amazon Channel',
    slug: 'film-total-amazon-channel',
    logo: null,
    id: '976f73f7-7133-4290-b651-b33aadae25fe',
  },
  {
    name: 'Turk On Video Amazon Channel',
    slug: 'turk-on-video-amazon-channel',
    logo: '/assets/logos/providers/turk-on-logo.svg',
    id: '976f73f7-7136-490c-8720-aad5f8c26db1',
  },
  {
    name: 'Cultpix',
    slug: 'cultpix',
    logo: '/assets/logos/providers/Cultpix-logo.webp',
    id: '976f73f7-7138-45bf-8821-87bd827b8c16',
  },
  {
    name: 'F1TV',
    slug: 'f1tv',
    logo: '/assets/logos/providers/F1-logo.svg',
    id: '976f73f7-713b-4b77-9b92-b9927ef2ab9a',
  },
  {
    name: 'FilmBox+',
    slug: 'filmbox',
    logo: '/assets/logos/providers/filmbox-logo.svg',
    id: '976f73f7-713d-43ba-94f9-a8f3a7b891eb',
  },
  {
    name: 'Sportschau',
    slug: 'sportschau',
    logo: '/assets/logos/providers/sportschau_logo.png',
    id: '976f73f7-713f-46bf-ad19-be73d0bb228d',
  },
  {
    name: 'AVA VOBB',
    slug: 'ava-vobb',
    logo: '/assets/logos/providers/ava-logo.webp',
    id: '976f73f7-7142-440f-80c7-35f90661559b',
  },
  {
    name: 'AVA HBZ',
    slug: 'ava-hbz',
    logo: '/assets/logos/providers/ava-Hbz-logo.svg',
    id: '976f73f7-7144-4b3f-bdc3-d1fc78d03cbb',
  },
  {
    name: 'Discovery Amazon Channel',
    slug: 'discovery-amazon-channel',
    logo: '/assets/logos/providers/discovery-channel-logo.svg',
    id: '976f73f7-7146-46a9-8881-45c459d47e11',
  },
  {
    name: 'BluTV Amazon Channel',
    slug: 'blutv-amazon-channel',
    logo: '/assets/logos/providers/BluTV-logo.svg',
    id: '976f73f7-7148-42dc-bf6c-f436902af8fa',
  },
  {
    name: 'Comedy Central Plus Amazon Channel',
    slug: 'comedy-central-plus-amazon-channel',
    logo: '/assets/logos/providers/comedy-central-logo.svg',
    id: '976f73f7-714b-47d3-aa6d-af101dd00914',
  },
  {
    name: 'HistoryPlay Amazon Channel',
    slug: 'historyplay-amazon-channel',
    logo: '/assets/logos/providers/history-logo.svg',
    id: '976f73f7-714d-4747-a2b0-9df44e644abb',
  },
  {
    name: 'GRJNGO Amazon Channel',
    slug: 'grjngo-amazon-channel',
    logo: '/assets/logos/providers/grjngo-logo.svg',
    id: '976f73f7-714f-4091-8913-390ed6b53c0c',
  },
  {
    name: 'MTV Plus Amazon Channel',
    slug: 'mtv-plus-amazon-channel',
    logo: '/assets/logos/providers/MTV_Logo.svg',
    id: '976f73f7-7152-4d27-b939-189ebb59d2e4',
  },
  {
    name: 'MGM Amazon Channel',
    slug: 'mgm-amazon-channel',
    logo: null,
    id: '976f73f7-7154-4823-b26a-f26d949d777e',
  },
  {
    name: 'Silverline Amazon Channel',
    slug: 'silverline-amazon-channel',
    logo: '/assets/logos/providers/silverline_logo.png',
    id: '976f73f7-7157-4bb0-86c8-28eafa520a5c',
  },
  {
    name: 'Sony AXN Amazon Channel',
    slug: 'sony-axn-amazon-channel',
    logo: '/assets/logos/providers/axn_logo.svg',
    id: '976f73f7-715a-4ff4-aae2-a27f92257123',
  },
  {
    name: 'RTL Passion Amazon Channel',
    slug: 'rtl-passion-amazon-channel',
    logo: '/assets/logos/providers/RTL-passion-logo.svg',
    id: '976f73f7-715c-4924-a83b-89fcedc3e282',
  },
  {
    name: 'UPC TV',
    slug: 'upc-tv',
    logo: '/assets/logos/providers/upc-logo.svg',
    id: 'needs-id',
  },
];
